import { FocusStyleManager } from '@blueprintjs/core';
import React, { Suspense } from 'react';
import { Route, Router, Switch, BrowserRouter } from 'react-router-dom';
import history from '~/browserHistory';
import lazyWithRetry from './shared/router/lazyWithRetry';

FocusStyleManager.onlyShowFocusOnTabs();

const NewUi = lazyWithRetry(() =>
  import(/* webpackChunkName: 'NewUI' */ './shared/router/AppRouter')
);

export default function App() {
  return (
    <Router history={history}>
      <BrowserRouter basename={(window as any).__POWERED_BY_QIANKUN__ ? '/' : '/'} />
      <Suspense fallback={''}>
        <Switch>
          <Route component={NewUi} />
        </Switch>
      </Suspense>
    </Router>
  );
}
